import React from "react"
import AppStoreImage from "./appStoreImage"
import { storeImages } from "./appStoreImages.module.css"

export default function AppStoreImages(props) {
  return (
    <div className={storeImages}>
      <AppStoreImage
        storeUrl={props.playStoreUrl}
        imageSrc={props.playStoreImageSrc}
        imageDesc={props.playStoreImageDesc}
      />
      <AppStoreImage
        storeUrl={props.appGalleryUrl}
        imageSrc={props.appGalleryImageSrc}
        imageDesc={props.appGalleryImageDesc}
      />
    </div>
  )
}

import React from "react"
import {
  container,
  attributionImage,
  attributionImageButton,
  attributionImageContainer,
  attributionContent,
  attributionTitle,
  attributionSubtitle,
} from "./attributionPeter.module.css"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Attribution() {

  const data = useStaticQuery(graphql`
    {
      attributionContent: file(relativePath: { eq: "attribution_peter.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_en
            subtitle_en
            image_desc_en
            call_to_action_en
          }
        }
      }
      attributionImage: file(
        relativePath: { eq: "en/can-you-lose-fat-and-gain-muscle-at-the-same-time.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

  const imageData = data.attributionImage.childImageSharp.gatsbyImageData
  const imageDescData = data.attributionContent.childMarkdownRemark.frontmatter.image_desc_en
  const imageDataCallToAction = data.attributionContent.childMarkdownRemark.frontmatter.call_to_action_en
  const titleData = data.attributionContent.childMarkdownRemark.frontmatter.title_en
  const subtitleData = data.attributionContent.childMarkdownRemark.frontmatter.subtitle_en
  const articleSlug = "/blog/weight-loss-inspiration"

  return (
    <div className={container}>
      <div className={attributionContent}>
        <h2 className={attributionTitle}>"{titleData}"</h2>
        <p className={attributionSubtitle}>{subtitleData}</p>
      </div>
      <Link to={articleSlug} className={attributionImageContainer}>
        <GatsbyImage
          image={imageData}
          className={attributionImage}
          alt={imageDescData}
        />
        <div className={attributionImageButton}>{imageDataCallToAction}</div>
      </Link>
    </div>
  )
}

import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { image } from "./appStoreImage.module.css"

export default function AppStoreImage(props) {
  return (
    <OutboundLink href={props.storeUrl}>
      <GatsbyImage
        image={props.imageSrc}
        className={image}
        alt={props.imageDesc}
      />
    </OutboundLink>
  )
}

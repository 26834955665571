import React from "react"
import FeatureReference from "./featureReference"
import Feature from "./feature"
import FeatureImageRight from "./featureImageRight"
import Attribution from "../attributionPeter/attributionPeter"
import { useStaticQuery, graphql } from "gatsby"

const Container = ({ children }) => <div styles={{}}>{children}</div>

export default function Features() {
  const data = useStaticQuery(graphql`
    {
      firstContent: file(relativePath: { eq: "content_2.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      firstImage: file(
        relativePath: { eq: "en/calorie-charts.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      secondContent: file(relativePath: { eq: "content_6.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      secondImage: file(
        relativePath: { eq: "en/app-that-scans-food-for-calories.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      thirdContent: file(relativePath: { eq: "content_5.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      thirdImage: file(relativePath: { eq: "en/chicken-breast-calorie-count.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      fourthContent: file(relativePath: { eq: "content_7.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      fourthImage: file(
        relativePath: {
          eq: "en/recipe-making-app.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      fifthContent: file(relativePath: { eq: "content_3.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      fifthImage: file(
        relativePath: { eq: "en/weight-tracker-app.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      sixthContent: file(relativePath: { eq: "content_4.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      sixthImage: file(relativePath: { eq: "en/free-printable-weight-loss-colouring-chart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      seventhContent: file(relativePath: { eq: "content_8.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      seventhImage: file(relativePath: { eq: "en/carb-manager.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      eighthContent: file(relativePath: { eq: "content_1.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_en
            title_en
            imageDesc_en
          }
        }
      }
      eighthImage: file(
        relativePath: { eq: "en/calories-burned-calculator.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      ninthImage: file(relativePath: { eq: "en/best-calorie-tracker-app.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      ninthContent: file(relativePath: { eq: "app_stores_link.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_en
            subtitle_en
            imageDesc_en
          }
        }
      }
      tenthImage: file(relativePath: { eq: "en/what-calorie-app-syncs-with-fitbit.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      tenthContent: file(relativePath: { eq: "content_9.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_en
            subtitle_en
            imageDesc_en
          }
        }
      }
    }
  `)
  return (
    <Container>
      <Feature
        excerpt={data.firstContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.firstContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.firstImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.firstContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <FeatureImageRight
        excerpt={data.secondContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.secondContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.secondImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.secondContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <Feature
        excerpt={data.thirdContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.thirdContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.thirdImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.thirdContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <FeatureImageRight
        excerpt={data.fourthContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.fourthContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.fourthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.fourthContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <FeatureReference
        articleSlug={"/blog/what-nutrition-apps-work-with-fitbit"}
        excerpt={data.tenthContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.tenthContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.tenthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.tenthContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <Attribution />
      <FeatureImageRight
        excerpt={data.fifthContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.fifthContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.fifthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.fifthContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <Feature
        excerpt={data.sixthContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.sixthContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.sixthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.sixthContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <FeatureImageRight
        excerpt={
          data.seventhContent.childMarkdownRemark.frontmatter.subtitle_en
        }
        title={data.seventhContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.seventhImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.seventhContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <Feature
        excerpt={data.eighthContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.eighthContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.eighthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.eighthContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
      <FeatureImageRight
        excerpt={data.ninthContent.childMarkdownRemark.frontmatter.subtitle_en}
        title={data.ninthContent.childMarkdownRemark.frontmatter.title_en}
        screenshot={data.ninthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.ninthContent.childMarkdownRemark.frontmatter.imageDesc_en
        }
      />
    </Container>
  )
}

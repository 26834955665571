import React from "react"
import {
  feature,
  screenshot,
  description,
  featureTitle,
  excerpt,
} from "./feature.module.css"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Feature(props) {
  return (
    <div className={feature}>
      <Link to={props.articleSlug} className={screenshot}>
        <GatsbyImage
          image={props.screenshot}
          alt={props.imageDesc || ""}
        />
      </Link>
      <div className={description}>
        <h2 className={featureTitle}>{props.title}</h2>
        <p className={excerpt}>{props.excerpt}</p>
      </div>
    </div>
  )
}

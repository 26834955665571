import React from "react"
import {
  container,
  header,
  title,
  subTitle,
  callToAction,
  screenshot
} from "./titlePreview.module.css"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function TitlePreview() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title_en
            subTitle_en
          }
        }
        header: file(relativePath: { eq: "header.md" }) {
          childMarkdownRemark {
            frontmatter {
              callToAction_en
              imageDesc_en
            }
          }
        }
        image: file(relativePath: {eq: "en/protein-counter-app.png"}) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    `
  )
  return (
    <div className={container}>
      <div className={header}>
        <h1 className={title}>{data.site.siteMetadata.title_en}</h1>
        <p className={subTitle}>{data.site.siteMetadata.subTitle_en}</p>
        <a href="#appstores" className={callToAction}>
          {data.header.childMarkdownRemark.frontmatter.callToAction_en}
        </a>
      </div>
      <GatsbyImage
        image={data.image.childImageSharp.gatsbyImageData}
        className={screenshot}
        alt={data.header.childMarkdownRemark.frontmatter.imageDesc_en || ""} />
    </div>
  )
}

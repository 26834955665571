import React from "react"
import {
  feature,
  screenshot,
  description,
  featureTitle,
  excerpt,
} from "./feature.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Feature(props) {
  return (
    <div className={feature}>
      <GatsbyImage
        image={props.screenshot}
        className={screenshot}
        alt={props.imageDesc || ""}
      />
      <div className={description}>
        <h2 className={featureTitle}>{props.title}</h2>
        <p className={excerpt}>{props.excerpt}</p>
      </div>
    </div>
  )
}

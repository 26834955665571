import React from "react"
import {
  feature,
  descriptionRight,
  featureTitle,
  excerpt,
  screenshot,
} from "./feature.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

export default function FeatureImageRight(props) {
  return (
    <div className={feature}>
      <div className={descriptionRight}>
        <h2 className={featureTitle}>{props.title}</h2>
        <p className={excerpt}>{props.excerpt}</p>
      </div>
      <GatsbyImage
        image={props.screenshot}
        className={screenshot}
        alt={props.imageDesc || ""}
      />
    </div>
  )
}

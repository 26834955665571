import React from "react"
import { container } from "./appStores.module.css"
import { useStaticQuery, graphql } from "gatsby"
import AppStoreImages from "./appStoreImages/appStoreImages"

export default function AppStores() {
  const data = useStaticQuery(graphql`
    {
      playstore: file(
        relativePath: { eq: "en/calories-burned-calculator-googleplay.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      appgallery: file(
        relativePath: { eq: "en/calories-burned-calculator-appgallery.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      storeLinks: file(relativePath: { eq: "app_stores_link.md" }) {
        childMarkdownRemark {
          frontmatter {
            googleplay
            appgallery
            googleplayDescription_en
            appgalleryDescription_en
          }
        }
      }
    }
  `)
  return (
    <div id="appstores" className={container}>
      <AppStoreImages
        playStoreUrl={
          data.storeLinks.childMarkdownRemark.frontmatter.googleplay
        }
        appGalleryUrl={
          data.storeLinks.childMarkdownRemark.frontmatter.appgallery
        }
        playStoreImageSrc={data.playstore.childImageSharp.gatsbyImageData}
        appGalleryImageSrc={data.appgallery.childImageSharp.gatsbyImageData}
        playStoreImageDesc={
          data.storeLinks.childMarkdownRemark.frontmatter
            .googleplayDescription_en
        }
        appGalleryImageDesc={
          data.storeLinks.childMarkdownRemark.frontmatter
            .appgalleryDescription_en
        }
      />
    </div>
  )
}

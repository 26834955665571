import React from "react"
import Container from "../components/container/container"
import TitlePreview from "../components/titlePreview/titlePreview"
import Features from "../components/features/features"
import AppStores from "../components/appStores/appStores"
import Navigation from "../components/navigation/navigation"
import Footer from "../components/footer/footer"
import Seo from "../components/seo/seo"
import Reference from "../components/reference/reference"
import Attribution from "../components/attribution/attribution"

export default function Home() {
  return (
    <Container>
      <Seo />
      <Navigation />
      <TitlePreview />
      <Attribution />
      <Reference />
      <Features />
      <AppStores />
      <Footer />
    </Container>
  )
}
